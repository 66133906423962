import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Answer, GameStatus, Mission, MissionTypeEnum } from '@freddy/models';

import { InGameState } from '../../core/stores/in-game.store';
import { MissionWithLocation } from '../containers/map-view/map-view.component';
import { distinctUntilChanged, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FirebaseStorageService, LocalStorageService } from '@freddy/common';

@Injectable({
  providedIn: 'root',
})
export class MissionService {
  constructor(
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
    private readonly firebaseStorageService: FirebaseStorageService,
  ) {}

  getNextMission(): Observable<MissionWithLocation | undefined> {
    return this.store.select(InGameState.game).pipe(
      switchMap((game) => {
        if (
          game?.status === GameStatus.STOPPED ||
          (game?.plannedEndDate &&
            game?.plannedEndDate.getTime &&
            game.plannedEndDate?.getTime() < new Date().getTime())
        ) {
          return of(undefined);
        }

        // If the game is ONGOING, proceed with the existing logic
        return this.store.select(InGameState.myTeam).pipe(
          distinctUntilChanged(
            (prev, curr) =>
              prev?.missionAccomplished?.length ===
              curr?.missionAccomplished?.length,
          ),
          map((myTeam) => {
            // Your existing logic here...
            if (myTeam?.teamNumber !== undefined) {
              const game = this.store.selectSnapshot(InGameState.game);
              if (game && game.scenario.missionsOrders) {
                const missions: Mission[] = game.scenario.missions;
                const missionsOrder: string[] = JSON.parse(
                  game.scenario.missionsOrders,
                )[myTeam.teamNumber % missions.length];
                const missionsWithLocation = missionsOrder
                  ?.map((missionUid) =>
                    missions.find((mission) => mission.uid === missionUid),
                  )
                  .filter((mission) => mission?.common.geolocalized === true)
                  .filter(
                    (mission) =>
                      !myTeam.missionAccomplished.includes(mission?.uid ?? ''),
                  ) as MissionWithLocation[];

                return missionsWithLocation.length > 0
                  ? missionsWithLocation[0]
                  : undefined;
              }
            }
            return undefined;
          }),
        );
      }),
    );
  }

  async handleMissionAnswer(
    response: Answer,
    mission: Mission,
  ): Promise<Answer> {
    const currentTeamPath = this.store.selectSnapshot(InGameState.teamPath);
    const answer = {
      ...response,
    };
    switch (answer.missionType) {
      case MissionTypeEnum.AUDIO: {
        const recordDataBase64: string = await this.localStorageService.get(
          answer.missionUid as string,
        );
        if (recordDataBase64) {
          const firebaseFile = await this.firebaseStorageService.uploadPathBlob(
            `${currentTeamPath}/answers/`,
            recordDataBase64,
          );
          answer.answer = firebaseFile.filePath;
        }
        break;
      }
      case MissionTypeEnum.VIDEO: {
        const video: Blob = await this.localStorageService.get(
          answer.missionUid as string,
        );
        if (video) {
          const firebaseFile = await this.firebaseStorageService.uploadBlob(
            `${currentTeamPath}/answers/`,
            video,
          );
          answer.answer = firebaseFile.filePath;
        }
        break;
      }
      case MissionTypeEnum.AR:
      case MissionTypeEnum.PHOTO: {
        if (answer.answer) {
          const firebaseFile = await this.firebaseStorageService.uploadPathBlob(
            `${currentTeamPath}/answers/`,
            answer.answer,
          );
          answer.answer = firebaseFile.filePath;
        }
        break;
      }
      case MissionTypeEnum.MULTIPLE_CHOICES: {
        answer.isEvaluated = true;
        const isCorrect =
          answer.answer ===
          mission?.metadata[MissionTypeEnum.MULTIPLE_CHOICES]?.selectedLine;
        if (isCorrect) {
          answer.points = mission?.common.points;
        } else {
          answer.points = 0;
        }
        break;
      }
      case MissionTypeEnum.OPEN: {
        break;
      }
      case MissionTypeEnum.GUIDED: {
        answer.isEvaluated = true;
        answer.points = answer.answer;
        break;
      }
      case MissionTypeEnum.MIN_MAX: {
        const margin = mission?.metadata['MIN_MAX']?.margin ?? 0;
        const correctAnswer = mission?.metadata['MIN_MAX']?.answer ?? -1;
        const isCorrect =
          correctAnswer + margin >= answer.answer &&
          correctAnswer - margin <= answer.answer;
        if (isCorrect) {
          answer.points = mission?.common.points;
        } else {
          answer.points = 0;
        }
        answer.isEvaluated = true;
        break;
      }
    }
    return answer;
  }
}
