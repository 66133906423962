import { CommonRepositoryAbstract } from '@freddy/common';
import { InGameState } from '../stores/in-game.store';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Answer } from '@freddy/models';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AnswerRepository extends CommonRepositoryAbstract<Answer> {
  constructor(
    firestore: Firestore,
    private readonly store: Store,
  ) {
    super(firestore);
  }
  protected getDocPath(): string {
    return `${this.store.selectSnapshot(InGameState.gamePath)}/answers`;
  }
}
