import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  ListenAnswersAction,
  SaveOrUploadMissionAnswerAction,
  SubmitMissionAnswerAction,
} from '../../game/actions/game.actions';
import { append, patch } from '@ngxs/store/operators';
import { InGameState } from './in-game.store';
import { Answer, Game, Mission, Team } from '@freddy/models';
import { GuidUtils, LaunchMissionAction } from '@freddy/common';
import { AnswerRepository } from '../repository/answer.repository';
import { tap } from 'rxjs/operators';
import { MissionService } from '../../game/services/mission.service';
import { TeamRepository } from '../repository/team.repository';
import { firstValueFrom } from 'rxjs';

export const MISSION_STATE_TOKEN = new StateToken<MissionStateModel>('mission');

export interface MissionStateModel {
  answers: Answer[];
  loading: boolean;
}

const defaultFormValue = {
  answers: [],
  loading: false,
};

@State<MissionStateModel>({
  name: MISSION_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class MissionState {
  constructor(
    private readonly router: Router,
    private readonly answerRepository: AnswerRepository,
    private readonly store: Store,
    private readonly zone: NgZone,
    private readonly teamRepository: TeamRepository,
    private readonly missionService: MissionService,
  ) {}

  @Selector()
  static loading(state: MissionStateModel): boolean {
    return state.loading;
  }

  @Selector([InGameState.game])
  static bonusMissions(game: Game | undefined): Mission[] {
    return (
      game?.scenario.missions.filter(
        (mission) => !mission.common.geolocalized,
      ) ?? []
    );
  }

  @Selector([MissionState.myAnswers, InGameState.game])
  static countNotAnsweredBonusMission(
    answers: Answer[],
    game: Game | undefined,
  ): number {
    return (
      game?.scenario.missions.filter((mission) => {
        const answer = answers.find(
          (answer) => answer.missionUid === mission.uid,
        );
        return !answer && !mission.common.geolocalized;
      }).length ?? 0
    );
  }

  @Selector()
  static answers(state: MissionStateModel): Answer[] {
    return state.answers;
  }

  @Selector([MissionState.answers, InGameState.myTeam])
  static myAnswers(answers: Answer[], myTeam: Team | undefined): Answer[] {
    return answers.filter((answer) => answer.teamUid === myTeam?.uid);
  }

  @Action(LaunchMissionAction)
  launchMissionAction(
    ctx: StateContext<MissionStateModel>,
    action: LaunchMissionAction,
  ) {
    if (
      this.store
        .selectSnapshot(InGameState.myTeam)
        ?.missionAccomplished?.includes(action.missionUid)
    ) {
      return;
    }
    const missionRoute = [
      'game',
      this.store.selectSnapshot(InGameState.game)?.code,
      'mission',
      action.missionUid,
      'start',
    ];
    if (
      this.router.url.indexOf('map') > -1 &&
      this.router.url.indexOf('mission') === -1
    ) {
      return this.zone.run(() => {
        return this.router.navigate(missionRoute, { skipLocationChange: true });
      });
    }
    return;
  }

  @Action(SubmitMissionAnswerAction)
  async submitMissionAnswerAction(
    ctx: StateContext<MissionStateModel>,
    action: SubmitMissionAnswerAction,
  ) {
    ctx.patchState({ loading: true });
    const currentTeamPath = this.store.selectSnapshot(InGameState.teamPath);
    const myTeam = this.store.selectSnapshot(InGameState.myTeam);
    if (currentTeamPath && myTeam) {
      // TODO: Should move to saveOrUploadMissionAnswerAction
      if (action.mission.common.geolocalized) {
        this.teamRepository.update({
          uid: myTeam.uid,
          missionAccomplished: [
            ...(myTeam.missionAccomplished ?? []),
            action.mission.uid,
          ],
        });
      } else {
        this.teamRepository.update({
          uid: myTeam.uid,
          missionBonusAccomplished: [
            ...(myTeam.missionBonusAccomplished ?? []),
            action.mission.uid,
          ],
        });
      }
      // END TODO

      const answer: Answer = {
        missionUid: action.mission.uid,
        answer: action.answer,
        missionType: action.mission.metadata.missionType,
        createdAt: new Date(),
        skipped: action.skipped,
        isEvaluated: action.skipped,
        teamUid: this.store.selectSnapshot(InGameState.myTeam)!.uid,
        uid: GuidUtils.generateUuid(),
      };

      ctx.dispatch(new SaveOrUploadMissionAnswerAction(answer));

      const missionRouteResult = [
        'game',
        this.store.selectSnapshot(InGameState.game)?.code,
        'mission',
        action.mission.uid,
        'result',
      ];

      return this.router.navigate(missionRouteResult, {
        skipLocationChange: true,
      });
    }
    return;
  }

  @Action(SaveOrUploadMissionAnswerAction)
  async saveOrUploadMissionAnswerAction(
    ctx: StateContext<MissionStateModel>,
    action: SaveOrUploadMissionAnswerAction,
  ) {
    const mission = this.store
      .selectSnapshot(InGameState.game)
      ?.scenario.missions.find((m) => m.uid === action.answer.missionUid);
    if (!mission) {
      return console.error('Mission not found');
    }

    const answer = await this.missionService.handleMissionAnswer(
      action.answer,
      mission,
    );

    await firstValueFrom(
      this.answerRepository.add(action.answer.skipped ? action.answer : answer),
    );

    ctx.setState(
      patch<MissionStateModel>({
        answers: append<Answer>([answer]),
        loading: false,
      }),
    );
  }

  @Action(ListenAnswersAction, { cancelUncompleted: true })
  listenAnswersAction(ctx: StateContext<MissionStateModel>) {
    return this.answerRepository.getCollectionsChanges().pipe(
      tap((answers) => {
        if (answers) {
          ctx.patchState({
            answers: answers,
          });
        }
      }),
    );
  }
}
